
















import { Component, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import AlarmItemDetail from '@/pages/dashboard/alarm/alarm-event-drawer.vue';

@Component({
    components: {
        'alarm-item-datail': AlarmItemDetail
    }
})
export default class AlarmItemDetailComponent extends BaseComponent {
    currentEventId: string = null;
    @Watch('$route.params', { deep: true, immediate: true })
    routechange(val: any, oldVal: any) {
        this.currentEventId = val.id;
    }
}

